
.details-form {
    display: flex;
    flex-direction: column;
    margin: 5rem;
}

.submit-button-container {
    display: flex;
    justify-content: center;
}